@font-face {
  font-family: AmpleSoft;
  src: url("AmpleSoftPro-Light.woff"),
    url("AmpleSoftPro-Light.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: AmpleSoft;
  src: url("AmpleSoftPro-Regular.woff"),
    url("AmpleSoftPro-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: AmpleSoft;
  src: url("AmpleSoftPro-Medium.woff"),
    url("AmpleSoftPro-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: AmpleSoft;
  src: url("AmpleSoftPro-Bold.woff"),
    url("AmpleSoftPro-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

::selection {
  background-color: #a6c307;
  color: #777777;
}

#root {
  font-family: "AmpleSoft", sans-serif;
  color: #3f3f3f;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AmpleSoft", sans-serif !important;
  font-weight: 500;
  line-height: 1.5;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.8rem;
  font-weight: 400;
}

h3 {
  font-size: 2.1rem;
  font-weight: 400;
  color: #707070;
}

h4 {
  font-size: 1.8rem;
  font-weight: 400;
  color: #707070;
}

h5 {
  font-size: 1.2rem;
  font-weight: 800;
}

h6 {
  font-size: 1.2rem;
  font-weight: 400;
}

p {
  margin: 0 0 1em;
  line-height: 1.3em;
}

p a {
  text-decoration: underline;
}

.message-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em 0;
}

.message-top .feedback-text i {
  display: none;
}

.row.main-header-row h6 {
  margin: 1em 0 0.1em;
}

.row.country-list-row-item p {
  line-height: 1.5em;
}

.column.pm-name-column a {
  display: flex;
  align-items: center;
}

.column.pm-name-column img {
  margin-right: 20px;
  padding-top: 1px;
}

.column.pm-name-column p {
  display: flex;
  flex-direction: column;
  font-weight: 800;
}

.column.pm-name-column p span {
  font-weight: 400;
  color: #777777;
  font-size: 0.9em;
}

.pm-result-title {
  font-weight: 700;
}

.payment-method-name {
  cursor: pointer;
  text-decoration: underline;
}

.arrow-back {
  cursor: pointer;
}

.payment-method-name:hover {
  color: hsl(69, 93%, 40%);
}

.payment-method-name:active {
  color: hsl(69, 93%, 40%);
}

.row.main-header-row {
  /*    color: hsl(0, 0%, 15%);*/
  font-weight: 800;
}

.column.column-title {
  font-weight: 500;
  color: hsl(74, 57%, 36%);
}

.ui.container {
  display: flex;
  flex-direction: column;
  width: 100vw !important;
}

.ui.fluid.search.pm-search-bar {
  margin: 0px;
  width: 100%;
}

.pm-search-result-link img {
  margin-right: 10px;
  width: 62px;
  border-radius: 2px;
}

.ui.input {
  width: 100%;
}

.ui.search .prompt {
  border-radius: 0.5rem;
}

.match-highlight {
  background-color: yellow;
}

.ui.items {
  width: 90vw !important;
  align-self: center;
  margin: 70px 0 20vh 0;
}

.ps__rail-y {
  display: none;
}

.ui.message:first-child {
  margin-top: 20px;
}

.ui.message:last-child {
  margin-bottom: 20px;
}

.important-note {
  font-size: 14px;
}

.ui.three.column.grid.headers {
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: "auto";
}

a {
  color: inherit;
  transition: color 0.3s ease !important;
}

a:hover {
  color: #438f29;
}

.ui.message p {
  opacity: 0.85;
  margin: 0.75em 0;
  font-size: 1em;
}

h1.ui.header {
  font-weight: 400;
  font-size: 4.2rem;
  color: white;
  line-height: 1;
  margin: 6px 0 24px 0;
}

.payu-logo img {
  width: 86px;
}

.message-block {
  text-align: center;
  background: #002124;
  color: #fff;
  padding-bottom: 40px;
}

i.question.circle.outline.icon {
  padding-left: 5px;
}

.message-block-text {
  font-size: 16px;
}

.message-block-details-link {
  margin-bottom: 15px;
}

.for-internal-use {
  font-size: 0.5em;
}

.ui.search .prompt {
  border-radius: 0.5rem;
}

.ui.segment {
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  border: none;
}

.ui.segment p {
  margin: 0.5em 0 2.5em;
  line-height: 1.6;
}

.ui.segment h5 + p {
  margin: 0 !important;
}

.ui.medium.image,
.ui.medium.images .image,
.ui.medium.images img,
.ui.medium.images svg {
  padding-right: 5%;
  padding-top: 5%;
}

.ui.icon.input {
  max-width: 50%;
  margin-bottom: 1px;
}

.ui.form {
  max-width: 784px;
  margin: 0 auto;
}

form.search-form {
  margin-top: 10px;
  font-size: 0.8em !important;
}

form.search-form input {
  top: 2px;
  position: relative;
  margin-right: 2px;
}

.ui.message {
  font-size: 1em;
  margin-left: auto;
  margin-right: auto;
}

.ui.huge.message.page.grid {
  margin-bottom: 10px;
}

.row {
  border-bottom: 1px #e3e4e2 solid;
}

.ui.icon.button.tooltip.column-heading {
  padding: 0px 0px 0px 5px;
}

.payment-method-tab {
  border: 0px !important;
}

.payment-details-pane {
  position: relative;
  width: 100vw;
}

.country-payment-method-name {
  display: block;
}

.payment-method-tabs {
  padding-bottom: 2px;
}

.details-page-brand {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e4e2;
  padding: 26px 0 48px 0;
}

.details-page-brand img {
  margin-right: 1em;
  border-radius: 4px;
}

.details-page-brand h2 {
  margin: 3px 0 0 0;
  line-height: 1;
}

th h6 {
  padding: 0;
  margin: 0;
  font-size: 1em;
}

ul.country-list {
  column-width: 100px;
  list-style-type: none;
  padding: 0, 0, 0, 0.5em;
  padding-left: 0.5em;
}

li.country-list-item {
  margin-bottom: 1em;
}

.ui.fluid.search > .results {
  width: 50%;
  max-height: 60vh;
  overflow: auto;
  margin-left: 25%;
  border-radius: 6px;
}

a.pm-search-result-link:hover .search-highlight {
  color: #438f29;
}

.search-highlight {
  transition: color 0.4s ease-in-out;
}

p.search-result-heading {
  color: hsl(0, 0%, 83%);
  margin: 15px 0px 5px 0px;
  font-size: 12px;
}

span.search-country-list-result::after {
  content: ",";
  display: inline-block;
}

span.search-country-list-result:last-child::after {
  content: none;
}

mark.search-highlight {
  background-color: #ffdd00;
  border-radius: 3px;
}

.ui.grid {
  margin: 0;
}

.ui.grid > .row > .column:first-child {
  padding-left: 0;
}

.ui.grid > .row > .column:last-child {
  padding-right: 0;
}

.feedback-link {
  color: white;
}

i.question.circle.outline.icon {
  color: white;
}

p.available-pms {
  font-size: 2em;
  opacity: 0.7;
  margin-bottom: 40px;
}

form {
  text-align: right;
  max-width: 75%;
}

button.ui.icon.button.fuzzy-tooltip {
  background-color: hsla(0, 0%, 0%, 0);
  padding: 0px 0px 10px 5px;
}

i.sort.small.icon.sort {
  float: right;
  font-size: 1rem;
  color: #bababa;
  cursor: pointer;
}

i.icon.outline {
  font-family: outline-icons;
  cursor: pointer;
}

.ui.pointing.secondary.menu a.item:hover {
  color: #438f29;
}

.ui.pointing.secondary.menu .active.item,
.ui.pointing.secondary.menu .active.item:hover {
  color: #438f29;
  border-color: #438f29;
}

.search-area .prompt {
  border: none !important;
  padding: 20px !important;
  box-shadow: hsla(69, 93%, 30%, 0.5) 0 0 16px,
    hsla(69, 93%, 30%, 0.4) 0 1px 6px !important;
  font-size: 1.1em !important;
  line-height: 1 !important;
  transition: box-shadow 0.2s ease-in-out !important;
}

.search-area .prompt:hover {
  box-shadow: hsla(185, 100%, 7%, 1) 0 0 36px,
    hsla(185, 100%, 7%, 0.4) 0 8px 4px !important;
}

.search-area .prompt + i {
  color: hsl(185, 100%, 7%) !important;
  opacity: 1 !important;
  margin-right: 10px !important;
}

p.country-list span {
  margin-right: 10px;
  display: inline-block;
  line-height: 2;
  text-decoration: underline;
}

p.country-list span::after {
  content: ",";
  display: inline-block;
}

p.country-list span:last-child::after {
  content: none;
}

.country-name-list span::after {
  text-decoration: underline;
  display: inline;
}

/* country details view */

img.country-flag-img {
  height: 40px;
  border-radius: 4px;
  box-shadow: #0000001c 0 1px 12px;
  min-width: 53.33px;
}

/*
.country-details-pm {
    padding: 10px;
    margin-top: 20px;
}
*/

.country-details-pm {
  margin-top: 20px;
}

.country-details-pm a {
  cursor: pointer;
  display: block;
  height: 100%;
  padding: 10px;
  box-shadow: #e3e4e2 0 4px 6px;
  border-radius: 6px;
  transition: box-shadow, color, 0.2s ease-in-out !important;
}

.country-details-pm a:hover {
  box-shadow: #e3e4e2 0 8px 10px;
}

.country-details-pm a img {
  border-radius: 4px;
}

span.title-in-rows {
  display: none;
}

footer {
  display: flex;
  justify-content: flex-start;
  bottom: 0;
  background: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 40%);
  width: 100vw;
  padding: 1.2em 2em 1em;
  font-size: 0.86em;
  line-height: 1;
  box-shadow: #0000001c 0px 0px 30px;
}

footer p {
  margin: 0 20px 0 0;
}

footer p a {
  text-decoration: underline;
}

.column.pm-cell-entry {
  align-self: center;
}

.ui.top.left.popup:before {
  display: none;
}

.ui.top.left.popup {
  font-family: AmpleSoft;
  font-size: 1em;
  color: #777777;
  border: none;
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .ui.items:not(.unstackable) > .item {
    margin: 0 !important;
  }
  h1.ui.header {
    font-size: 3.6rem;
  }
  .details-page-brand h2 {
    font-size: 2.2rem;
    margin-top: 7px;
  }
  .row.main-header-row {
    display: none !important;
  }
  .payment-details-pane {
    width: 90vw;
    align-self: center;
  }
  .country-details-pm-list {
    grid-template-columns: 32% 32% 32% !important;
  }
  .pm-name-column p span {
    letter-spacing: normal;
    font-size: 0.8em !important;
  }
  .row.country-list-row-item > .pm-cell-entry p::after {
    content: "\2013";
    display: block;
    line-height: 0.5;
    color: #dadada;
    padding-bottom: 2px;
  }
  .row.country-list-row-item > .pm-cell-entry p:last-child::after {
    display: none;
  }
  .details-page-brand,
  .details-page-brand + h6 {
    /*        padding: 0 1em;*/
  }
  .ui.segment {
    padding: 0 1em !important;
  }
  .ui.items {
    /*
        padding: 0;
        margin: 0;
*/
  }
  .ui.three.column.grid .row.main-header-row {
    display: none;
  }
  .pm-name-column p {
    margin-top: 5px;
    font-size: 1.2em;
    font-weight: 900 !important;
    letter-spacing: 0.03em;
    line-height: 1em !important;
  }
  .item > div > h2 {
    line-height: 1;
    padding-bottom: 20px;
  }
  .row.country-list-row-item {
    /*
        flex-direction: row !important;
        padding: 3.1em 1em 2.9em !important;
*/
  }
  .row.country-list-row-item > div {
    width: 100% !important;
    padding: 0 !important;
  }
  .row.country-list-row-item > .pm-cell-entry p {
    margin-bottom: 0;
  }
  .row.country-list-row-item div:nth-child(2) p::before {
    content: "Shopper Countries";
    color: #777777;
    display: block;
    padding-top: 1em;
    font-size: 0.8em;
  }
  .row.country-list-row-item div:nth-child(3) p:first-child::before {
    content: "Processing Platform";
    color: #b2b2b2;
    display: block;
    padding-top: 1em;
  }
  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .message-top {
    display: flex;
    justify-content: space-between;
    padding: 1em 0 0 !important;
    max-width: 90%;
    margin: 0 auto;
  }
  .search-area .ui.icon.input {
    max-width: 90vw;
  }
  .message-top .feedback-text u {
    display: none;
  }
  .message-top .feedback-text i {
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: white 1px solid;
    width: 38px;
    height: 38px;
    margin: 0;
    font-size: 1.2em;
  }
  form.search-form {
    max-width: 90% !important;
    margin: 0 auto;
    padding: 0 !important;
  }
  form.search-form label i {
    margin: 0;
    padding: 1em;
  }
  .ui.fluid.search > .results {
    width: 90vw !important;
    max-width: 90vw !important;
    max-height: 60vh !important;
    margin: 0 !important;
    left: 5%;
  }
  .table-pm-data {
    overflow-x: hidden !important;
  }
  thead.table-header {
    display: none !important;
  }
  span.title-in-rows {
    display: inline !important;
    font-weight: 500;
  }
  span.title-in-rows::after {
    content: ": ";
    white-space: pre;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1;
  }
}

@media only screen and (max-width: 360px) {
  h1.ui.header {
    font-size: 1.8em !important;
    margin: 20px 0 10px;
  }
  .country-details-pm-list {
    grid-template-columns: 49% 49% !important;
  }
  img.country-flag-img {
    width: 20% !important;
  }
  p.country-list span {
    margin-right: 22px;
    line-height: 2.4;
  }
  footer {
    flex-direction: column;
  }
  footer p {
    line-height: 2.5 !important;
  }
}

/* Search Results */

.results .active {
  display: flex !important;
  flex-direction: column !important;
}

.results .category .name,
.results .category .results,
.results .category .result {
  display: flex !important;
  flex-direction: column !important;
  height: max-content !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  width: 100% !important;
}

a.pm-search-result-link {
  display: flex;
  align-items: center;
  padding: 4px 10px;
}

.ui.category.search > .results .category {
  display: block !important;
}

.ui.category.search > .results .category > .name {
  color: hsl(0, 0%, 25%) !important;
  font-weight: 500;
  padding: 10px 0 8px 10px !important;
  font-size: 1rem;
  line-height: 1 !important;
}

span.payment-method-group {
  display: block;
  color: #777777;
  font-weight: 400;
}

.ui.category.search > .results .category {
  background-color: white !important;
}

.ui.category.search > .results .category {
  background-color: white !important;
}

/*
.country-details-pm-list {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}
*/

.country-details-pm-list {
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  grid-auto-flow: row;
  grid-column-gap: 2%;
  grid-row-gap: 20px;
}

span.country-payment-method-name {
  font-weight: 800;
}

.ui.input > input::placeholder {
  color: #c2c2c2 !important;
}

.input input,
.results.visible * {
  font-family: "AmpleSoft", sans-serif !important;
}

.ui.search > .results {
  margin-top: 0 !important;
  box-shadow: hsla(0, 0%, 0%, 0.3) 0 20px 60px !important;
  border: none;
}

.ui.category.search > .results .category .result.active,
.ui.search > .results .result.active,
.ui.category.search > .results .category .result {
  /*    background-color: #ffffff !important;*/
  border: none !important;
  cursor: pointer !important;
}

.ui.category.search > .results .category .result.active:hover,
.ui.search > .results .result.active:hover,
.ui.category.search > .results .category .result:hover {
  background-color: #f7f7f7 !important;
  border: none !important;
}

.category .name,
.active.category .name {
  border: none !important;
  background-color: #f7f7f7 !important;
}

.ui.category.search > .results .category .results {
  border: none !important;
}

/* back to top */

#back-to-top + #back-to-top {
  display: none;
}

#back-to-top {
  background: #002124;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  bottom: 20px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 56px;
  opacity: 1;
  outline: 0;
  position: fixed;
  right: 20px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-transition: bottom 0.2s, opacity 0.2s;
  -o-transition: bottom 0.2s, opacity 0.2s;
  -moz-transition: bottom 0.2s, opacity 0.2s;
  transition: bottom 0.2s, opacity 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 56px;
  z-index: 1;
  transition: all ease 0.3s;
}

#back-to-top:hover {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2) !important;
}

#back-to-top svg {
  display: block;
  fill: currentColor;
  height: 24px;
  margin: 16px auto 0;
  width: 24px;
}

#back-to-top.hidden {
  bottom: -56px;
  opacity: 0;
}

.ui.table {
  /*    font-size: .9rem;*/
  color: inherit;
}

.ui.search > .results .result {
  font-size: 0.9em;
}

.details-page-brand + p {
  padding-top: 20px;
}

i.arrow.left.icon {
}

.back-arrow {
  background: white;
  width: 60px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 1;
  position: absolute;
  top: -100px;
  box-shadow: 0 0 16px rgba(0, 33, 36, 1), 0 1px 6px rgba(0, 33, 36, 0.4) !important;
  transition: box-shadow, color, 0.2s ease-in-out !important;
}

.back-arrow:hover {
  box-shadow: 0 0 30px #002124, 0 5px 5px #7e940566 !important;
}

a.back-arrow i {
  margin: 0;
}
